<template>
  <div class="desdehasta">
    <div>
      <div
        class="desdehasta-label-container desdehasta-label-container-left"
        @click="abrirDesde()"
      >
        <label v-show="!mostrarDesde" class="desdehasta-label">Desde</label>
        <label v-show="mostrarDesde" class="desdehasta-fecha">{{
          getFecha(desde)
        }}</label>
      </div>
      <DatePick
        ref="desde"
        v-model="desde"
        :weekdays="weekdays"
        :months="months"
        @input="setDesde"
      ></DatePick>
    </div>
    <div>
      <div class="desdehasta-label-container" @click="abrirHasta()">
        <label v-show="!mostrarHasta" class="desdehasta-label">Hasta</label>
        <label v-show="mostrarHasta" class="desdehasta-fecha">{{
          getFecha(hasta)
        }}</label>
      </div>
      <DatePick
        ref="hasta"
        v-model="hasta"
        :weekdays="weekdays"
        :months="months"
        @input="setHasta"
      ></DatePick>
    </div>
  </div>
</template>

<script>
import DatePick from "vue-date-pick/src/vueDatePick";
import "vue-date-pick/dist/vueDatePick.css";

export default {
  name: "DesdeHasta",
  props: {},
  components: { DatePick },
  data() {
    return {
      desde: null,
      hasta: null,
      mostrarDesde: false,
      mostrarHasta: false,
      weekdays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  },
  mounted() {},
  methods: {
    abrirDesde() {
      var that = this;
      setTimeout(function () {
        that.$refs.desde.$el.children[0].click();
      }, 100);
    },
    abrirHasta() {
      var that = this;
      setTimeout(function () {
        that.$refs.hasta.$el.children[0].click();
      }, 100);
    },
    getFecha(date) {
      if (date != null) {
        return date.split("-").reverse().join("-");
      } else {
        return "";
      }
    },
    setHasta(hasta) {
      this.mostrarHasta = true;
      this.hasta = hasta;
      this.$emit("hasta", hasta);
    },
    setDesde(desde) {
      this.mostrarDesde = true;
      this.desde = desde;
      this.$emit("desde", desde);
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/components/desdehasta.scss"></style>
