<template>
  <transition name="fade-fast">
    <div v-show="mostrar" class="popup-container">
      <div class="popup-mask"></div>
      <div class="popup">
        <div class="popup-top">
          <img src="../assets/img/icon/close_popup.png" @click="cerrar()" />
          <label>Salir de {{ tipo }}</label>
        </div>
        <div class="popup-body">
          <label class="popup-text"
            >Se perderá la información ingresada hasta ahora, ¿Deseas salir de
            todas formas?</label
          >
        </div>
        <div class="popup-btn-aceptar-cancelar">
          <button class="button button-secondary" @click="cerrar()">
            Cancelar
          </button>
          <button class="button button-primary" @click="aceptar()">
            Salir
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Salir",
  props: {
    tipo: String,
    mostrar: Boolean,
  },
  components: {},
  mounted() {},
  methods: {
    cerrar() {
      this.$emit("cerrar");
    },
    aceptar() {
      this.$emit("aceptar");
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/popup.scss"></style>
